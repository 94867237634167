import React, { useState, Component } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { CSSTransition } from 'react-transition-group'

import _ from 'lodash'

// Images
import imgLogo from 'img/logo.svg'
import phoneIcon from 'img/phone-icon-white.svg'

// Components
import CustomLink from 'components/CustomLink'
import Content from 'components/Content'

// CSS
import './Header.scss'

const Header = ({ isSticky }) => {
  const [isCollapsed, setCollapsed] = useState(true)

  const { headerItems, headerCta } = useStaticQuery(graphql`
    {
      headerItems: wordpressMenusMenusItems(term_id: { eq: 11 }) {
        name
        items {
          title
          url
          child_items {
            title
            url
            slug
          }
        }
      }
      headerCta: wordpressWpComponents(wordpress_id: { eq: 284 }) {
        acf {
          phone_header
        }
      }
    }
  `)

  const headerContent = headerItems

  return (
    <nav className={`header position-fixed${isSticky ? ' header-sticky' : ''}`}>
      <div className="navbar-container container justify-content-lg-between position-relative">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <img src={imgLogo} className="logoimg" alt="" />
          </Link>
        </div>
        <div className="logo-name d-none d-xl-flex text-uppercase">
          <span>Schildersbedrijf</span>
          <span>Koelewijn-Crookewit</span>
        </div>
        <button
          type="button"
          onClick={() => setCollapsed(!isCollapsed)}
          className={`navbar-collapse-button${
            isCollapsed ? ' navbar-collapse-button-collapsed' : ''
          } text-center d-block d-lg-none`}
        >
          {isCollapsed ? (
            <>
              <span className="navbar-collapse-button-stripe d-block" />
              <span className="navbar-collapse-button-stripe d-block" />
              <span className="navbar-collapse-button-stripe d-block" />
            </>
          ) : (
            <div className="navbar-collapse-button-close" />
          )}
        </button>

        <HeaderMenuMobile
          items={headerContent.items}
          isCollapsed={isCollapsed}
          setCollapse={setCollapsed}
        />
        <HeaderMenuDesktop items={headerContent.items} />
        <div className="header-cta position-absolute d-flex align-items-center text-center">
          <div className="col d-flex align-items-center text-center">
            <img src={phoneIcon} width="24" alt="Bel ons" />
            <a href={`tel:${headerCta.acf.phone_header}`}>{headerCta.acf.phone_header}</a>
          </div>
        </div>
      </div>
    </nav>
  )
}

const HeaderMenuMobile = ({ isCollapsed, items }) => (
  <CSSTransition
    in={!isCollapsed}
    timeout={300}
    unmountOnExit
    classNames="header-menu"
  >
    <div className="navbar-menu">
      <ul className="navbar-items">
        <HeaderMenuItems items={items} />
      </ul>
    </div>
  </CSSTransition>
)

const HeaderMenuDesktop = ({ items }) => (
  <ul className="navbar-items d-none d-lg-flex">
    <HeaderMenuItems items={items} />
  </ul>
)

const HeaderMenuItems = ({ items }) => (
  <>
    {items.map((item, index) => (
      <li key={index}>
        <span
          className={`header-a${
            item.child_items !== null ? ' has-children' : ''
          }`}
        >
          <Link to={item.url}>{item.title}</Link>

          {item.child_items !== null && (
            <>
              <ul className="sub-menu">
                {item.child_items.map((child_item, key) => (
                  <li key={key} className="sub-menu-item">
                    <Link to={child_item.url}>
                      <span
                        dangerouslySetInnerHTML={{ __html: child_item.title }}
                      />
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          )}
        </span>
      </li>
    ))}
  </>
)

export default Header
