import React, { useEffect, useState } from 'react'
import CookieConsent from 'react-cookie-consent'

// Images
import whatsApp from 'img/whatsapp-icon.svg'

// Components
import Header from './Header'
import Footer from './Footer'

// CSS
import 'styles/all.scss'

const Layout = ({ children }) => {
  const [isSticky, setSticky] = useState(false)

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  return (
    <>
      <Header isSticky={isSticky} />
      <>{children}</>
      <Footer />
      <div className="whatsapp-div">
        <a
          href="https://wa.me/+31623825681?text=Contact"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={whatsApp} width="50" alt="Whatsapp ons" />
        </a>
      </div>
      <CookieConsent
        acceptOnScroll
        acceptOnScrollPercentage={20}
        location="bottom"
        style={{ backgroundColor: '#1f1f1f', color: '#FFFFFF' }}
        buttonClasses="d-none"
      >
        We gebruiken cookies om ervoor te zorgen dat onze website zo soepel
        mogelijk draait. Als je doorgaat met het gebruiken van de website, gaan
        we er vanuit dat je ermee instemt.
      </CookieConsent>
    </>
  )
}

export default Layout
