import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import _ from 'lodash'

import './FooterJobs.scss'

// Images
import arrow from 'img/arrow-right-color.svg'

const Jobs = () => {
  const {
    jobs: { edges: jobsList },
  } = useStaticQuery(
    graphql`
      {
        jobs: allWordpressWpVacatures(
          sort: { fields: date, order: ASC }
          limit: 2
        ) {
          edges {
            node {
              id
              title
              path
            }
          }
        }
      }
    `
  )

  return (
    <div className="row-jobs col-12 p-0">
      {_.map(jobsList, (job, index) => (
        <div key={index} className="col-md-12 d-flex flex-wrap p-0 align-items-center ">
          <h4>
            <Link to={job.node.path}>
              <img src={arrow} width="8" alt="" />
              {job.node.title}
            </Link>
          </h4>
        </div>
      ))}
    </div>
  )
}
export default Jobs
