import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

// Lodash
import _ from 'lodash'

// COMPONENT
import FooterJobs from 'components/Jobs/FooterJobs'
import { TitleDefault, ButtonLineDefault } from '../Elements'


// CSS
import './Footer.scss'

// Images
import logo from '../../img/logo.svg'
import mail from '../../img/mail-icon.svg'
import phone from '../../img/phone-icon.svg'
import placeholder from '../../img/adres_icon.svg'
import instagram from '../../img/instagram_white.svg'
import facebook from '../../img/facebook_white.svg'
import twitter from '../../img/twitter_white.svg'
import beeldmerk from '../../img/sbb-beeldmerk.png'

const Footer = () => {
  const { footer, footerMenu } = useStaticQuery(
    graphql`
      {
        footer: wordpressWpComponents(wordpress_id: { eq: 81 }) {
          id
          acf {
            footer_top {
              subtitle_topfooter
              title_topfooter
            }
            contact {
              contact_adres
              email_footer
              phone_footer
              second_phone_footer
            }
            social_footer {
              facebook_url
              instagram_url
              twitter_url
            }
          }
        }
        footerMenu: wordpressMenusMenusItems(term_id: { eq: 12 }) {
          name
          items {
            title
            url
          }
        }
      }
    `
  )
  const siteMap = footerMenu;
  return (
    <div className="footer ">
      <div className="footer-top py-5">
        <div className="container ">
          <div className="row">
            <div className="col-12 col-md-8 footer-top-title">
              <TitleDefault>
                <span>{footer.acf.footer_top.title_topfooter}</span>
                <br />
                <strong>{footer.acf.footer_top.subtitle_topfooter}</strong>
              </TitleDefault>
            </div>
            <div className="col-12 col-md-4">
              <a
                href="mailto:brian@koelewijn-crookwit.nl?Subject=Sollicitatie"
                className="w-100 mb-4 button button-line-default"
                target="_blank"
                rel="noopener noreferrer"
              >
                Solliciteer direct
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-12 col-md-2 mb-5">
            <img src={logo} width="117" alt="" />
          </div>
          <div className="col-12 col-md-4 mb-5">
            <TitleDefault>Contact</TitleDefault>
            <div className="adres">
              <img src={placeholder} alt="" width="15" />
              <div
                dangerouslySetInnerHTML={{
                  __html: footer.acf.contact.contact_adres,
                }}
              />
            </div>
            <div className="contact">
              <a
                href={`tel:${footer.acf.contact.phone_footer}`}
                className="phone"
              >
                <img src={phone} width="16" alt="Bel ons" />
                {footer.acf.contact.phone_footer}
              </a>
              <a
                href={`tel:${footer.acf.contact.second_phone_footer}`}
                className="phone"
              >
                <img src={phone} width="16" className="invisible" alt="Bel ons"  />
                {footer.acf.contact.second_phone_footer}
              </a>
              <br />
              <a
                href={`mailto:${footer.acf.contact.email_footer}`}
                className="mail"
              >
                <img src={mail} width="16" alt="Mail ons" />
                {footer.acf.contact.email_footer}
              </a>
            </div>
          </div>
          <div className="col-12 col-md-3 mb-5">
            <TitleDefault>Diensten</TitleDefault>
            <nav className="footer-menu menu">
              <ul>
                {siteMap.items.map(( item, index ) => (
                  <li key={index}>
                    <Link to={item.url}>
                      <span dangerouslySetInnerHTML={{ __html: item.title }} />
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <div className="col-12 col-md-3 mb-5">
            <TitleDefault>Vacatures</TitleDefault>
            <FooterJobs />
            <div className="social-group mt-5">
              <a href={footer.acf.social_footer.instagram_url} target="_blank" rel="noopener noreferrer">
                <img src={instagram} width="28" alt="Instragram" />
              </a>
              <a href={footer.acf.social_footer.facebook_url} target="_blank" rel="noopener noreferrer">
                <img src={facebook} width="28" alt="Instragram" />
              </a>
              <a href={footer.acf.social_footer.twitter_url} target="_blank" rel="noopener noreferrer">
                <img src={twitter} width="28" alt="Instragram" />
              </a>
            </div>
            <div className="beeldmerk mt-3">
              <img src={beeldmerk} width="150" alt="Wij leiden vakmensen op" />
            </div>
          </div>
        </div>
      </div>
      <div className="empty-space-50 d-none d-lg-block" />
    </div>
  )
}

export default Footer
